import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import HuUploads from 'wms/MHUs/hu';

export default function HUsContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <div className="bg-background h-full">
          <Router>
            <HuUploads path="/wms/MHUs" />
          </Router>
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
