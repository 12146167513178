import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useWMSHUsDownload } from 'apiHooks/wmsHooks';
import React from 'react';
import { useBranchId } from 'utils/customHooks';

export const useHuUploads = () => {
  const defaultButtons = [
    {
      type: '/download',
      name: 'Download',
      isPost: false,
      className: 'text-blue_secondary',
      icon: <CloudDownloadIcon />,
    },
    {
      type: '/upload',
      name: 'Upload',
      isPost: true,
      className: 'text-light_blue',
      icon: <CloudUploadIcon />,
    },
  ];

  const uploadList = [
    {
        name: 'HU Kind',
        url: 'handling_unit/kind/upsert/xlsx',
        buttons: defaultButtons.map((btn) => {
          return {
            ...btn,
            type: btn.name === 'Download' ? '/download' : ''
          };
        }),
      },
  ];

  const branchId = useBranchId();
  const [download, { status: downloadStatus }] = useWMSHUsDownload({ branchId });
  const isFetching = downloadStatus === 'loading';

  return { uploadList, isFetching, download };
};
